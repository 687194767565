const API_VERSION = 'api/1.0.0';

const {REACT_APP_BASE_URL, REACT_APP_IMAGE_URL} = process.env;

const API_URL = `${REACT_APP_BASE_URL}/${API_VERSION}`;
const SECURED_URL = `${API_URL}/secured`;
const PUBLIC_URL = `${API_URL}`;
const TOKENIZED = `tokenized`;

export const FILE_SERVER = REACT_APP_IMAGE_URL;
export const RETRIEVE_IMAGE_URL = `${PUBLIC_URL}/aws/get/files`;

//auth
export const USER_SIGNIN_URL = `${API_URL}/users/login`;
export const BRANCH_SIGNIN_URL = `${API_URL}/${TOKENIZED}/branches/login`;
export const OWNER_SIGNIN_URL = `${API_URL}/${TOKENIZED}/owners/login`;
export const USER_REFRESH_TOKEN = `${API_URL}/users/refresh-token`;

// profile
export const GET_USER_PROFILE = `${SECURED_URL}/${TOKENIZED}/users/profile`;

// user
export const CREATE_ROLE = `${SECURED_URL}/roles/create`;
export const GET_ALL_ROLE = `${SECURED_URL}/roles`;
export const GET_ALL_PERMISSION = `${SECURED_URL}/roles/permissions`;
export const GET_ROLE_BY_ID = `${SECURED_URL}/roles/id`;
export const UPDATE_ROLE = `${SECURED_URL}/roles/update`;
export const CHANGE_USER_PASSWORD = `${SECURED_URL}/${TOKENIZED}/users/change-password`;
export const GET_ROLES_BY_RESTAURANT = `${SECURED_URL}/restaurant-roles/by-restaurant`;

// user
export const CREATE_USER = `${SECURED_URL}/${TOKENIZED}/users/create`;
export const GET_ALL_USER = `${SECURED_URL}/${TOKENIZED}/users`;
export const GET_USER_BY_ID = `${SECURED_URL}/${TOKENIZED}/users/id`;
export const UPDATE_USER = `${SECURED_URL}/${TOKENIZED}/users/update`;
export const UPDATE_USER_PROFILE = `${SECURED_URL}/${TOKENIZED}/users/profile/update`;

// branch
export const CREATE_BRANCH = `${SECURED_URL}/${TOKENIZED}/branches/create`;
export const GET_ALL_BRANCH = `${SECURED_URL}/${TOKENIZED}/branches`;
export const GET_BRANCH_BY_ID = `${SECURED_URL}/${TOKENIZED}/branches/id`;
export const UPDATE_BRANCH = `${SECURED_URL}/${TOKENIZED}/branches/update`;
export const ASSIGN_EMPLOYEE = `${SECURED_URL}/${TOKENIZED}/branches/add/employee`;
export const GET_ALL_BRANCH_EMPLOYEE = `${SECURED_URL}/${TOKENIZED}/branches/employee-list`;
export const GENERATE_QR_CODE_URL = `${SECURED_URL}/${TOKENIZED}/branches/generate/qr-code`;
export const GENERATE_MENU_QR_CODE_URL = `${SECURED_URL}/${TOKENIZED}/branches/generate/qr-code/for-menu`;


// product
export const CREATE_PRODUCT = `${SECURED_URL}/${TOKENIZED}/products/create`;
export const GET_ALL_PRODUCT = `${SECURED_URL}/${TOKENIZED}/products`;
export const GET_PRODUCT_BY_ID = `${SECURED_URL}/${TOKENIZED}/products/id`;
export const UPDATE_PRODUCT = `${SECURED_URL}/${TOKENIZED}/products/update`;

// requested product
export const GET_ALL_REQUESTED_PRODUCT = `${SECURED_URL}/${TOKENIZED}/products/requested-list`;

// branch request product
export const CREATE_BRANCH_REQUEST_PRODUCT = `${SECURED_URL}/${TOKENIZED}/products/for-branch/create-request`;
export const GET_ALL_BRANCH_REQUEST_PRODUCT = `${SECURED_URL}/${TOKENIZED}/products/for-branch`;
export const GET_BRANCH_REQUEST_PRODUCT_BY_ID = `${SECURED_URL}/${TOKENIZED}/products/for-branch/id`;
export const UPDATE_BRANCH_REQUEST_PRODUCT = `${SECURED_URL}/${TOKENIZED}/products/for-branch/update-request`;

// product category
export const CREATE_PRODUCT_CATEGORY = `${SECURED_URL}/${TOKENIZED}/categories/create`;
export const GET_ALL_PRODUCT_CATEGORY = `${SECURED_URL}/${TOKENIZED}/categories`;
export const GET_PRODUCT_CATEGORY_BY_ID = `${SECURED_URL}/${TOKENIZED}/categories/id`;
export const UPDATE_PRODUCT_CATEGORY = `${SECURED_URL}/${TOKENIZED}/categories/update`;

// categories for branch
export const GET_ALL_PRODUCT_CATEGORY_FOR_BRANCH = `${SECURED_URL}/${TOKENIZED}/categories/for-branch`;
export const GET_ALL_PRODUCT_CATEGORY_FOR_OUR_MENU = `${PUBLIC_URL}/${TOKENIZED}/all/category-name`;

// catering section
export const GET_ALL_CATERING_SECTION = `${SECURED_URL}/${TOKENIZED}/catering-sections`;
export const CREATE_CATERING_SECTION = `${SECURED_URL}/${TOKENIZED}/catering-sections/create`;
export const GET_CATERING_SECTION_BY_ID = `${SECURED_URL}/${TOKENIZED}/catering-sections/id`;
export const UPDATE_CATERING_SECTION = `${SECURED_URL}/${TOKENIZED}/catering-sections/update`;

// product add-ons
export const CREATE_PRODUCT_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/add-ons/create`;
export const GET_ALL_PRODUCT_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/add-ons`;
export const GET_PRODUCT_ADD_ONS_BY_ID = `${SECURED_URL}/${TOKENIZED}/add-ons/id`;
export const UPDATE_PRODUCT_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/add-ons/update`;

// requested add-ons
export const GET_ALL_REQUESTED_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/add-ons/requested-list`;

// branch request add-ons
export const CREATE_BRANCH_REQUEST_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/add-ons/for-branch/create-request`;
export const GET_ALL_BRANCH_REQUEST_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/add-ons/for-branch`;
export const GET_BRANCH_REQUEST_ADD_ONS_BY_ID = `${SECURED_URL}/${TOKENIZED}/add-ons/for-branch/id`;
export const UPDATE_BRANCH_REQUEST_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/add-ons/for-branch/update-request`;

// branch add-ons
export const CREATE_BRANCH_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/branch-add-ons/assign`;
export const GET_ALL_BRANCH_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/branch-add-ons`;
export const GET_BRANCH_ADD_ONS_BY_ID = `${SECURED_URL}/${TOKENIZED}/branch-add-ons/id`;
export const UPDATE_BRANCH_ADD_ONS = `${SECURED_URL}/${TOKENIZED}/branch-add-ons/update`;

// branch product
export const ASSIGN_BRANCH_PRODUCT = `${SECURED_URL}/${TOKENIZED}/branch-products/assign`;
export const ADD_RELATED_PRODUCT = `${SECURED_URL}/${TOKENIZED}/branch-products/add-related-product`;
export const GET_ALL_BRANCH_PRODUCT = `${SECURED_URL}/${TOKENIZED}/branch-products`;
export const GET_BRANCH_PRODUCT_BY_ID = `${SECURED_URL}/${TOKENIZED}/branch-products/id`;
export const GET_ALL_RELATED_PRODUCT = `${SECURED_URL}/${TOKENIZED}/branch-products/get-related-product`;
export const UPDATE_BRANCH_PRODUCT = `${SECURED_URL}/${TOKENIZED}/branch-products/update`;
export const GET_BRANCH_PRODUCT_INGREDIENTS = `${SECURED_URL}/${TOKENIZED}/branch-product-ingredients/by-branch-product`;
export const UPDATE_BRANCH_PRODUCT_INGREDIENTS = `${SECURED_URL}/${TOKENIZED}/branch-product-ingredients/update`;

// promo
export const CREATE_PROMO = `${SECURED_URL}/${TOKENIZED}/promos/create`;
export const GET_ALL_PROMO = `${SECURED_URL}/${TOKENIZED}/promos`;
export const GET_PROMO_BY_ID = `${SECURED_URL}/${TOKENIZED}/promos/id`;
export const UPDATE_PROMO = `${SECURED_URL}/${TOKENIZED}/promos/update`;

// table
export const CREATE_TABLE = `${SECURED_URL}/${TOKENIZED}/branch-tables/create`;
export const GET_ALL_TABLE = `${SECURED_URL}/${TOKENIZED}/branch-tables`;
export const GET_ALL_TABLE_ORDER_STATESTICS = `${SECURED_URL}/${TOKENIZED}/branch-tables/order-statistics`;
export const GET_TABLE_BY_ID = `${SECURED_URL}/${TOKENIZED}/branch-tables/by-id`;
export const UPDATE_TABLE = `${SECURED_URL}/${TOKENIZED}/branch-tables/update`;
export const GET_ORDER_LIST_BY_TABLE_ID = `${SECURED_URL}/${TOKENIZED}/branch-tables/ongoing-orders`;

// banner
export const CREATE_BANNER_URL = `${SECURED_URL}/${TOKENIZED}/banners/create`;
export const GET_ALL_BANNER_URL = `${SECURED_URL}/${TOKENIZED}/banners`;
export const GET_BANNER_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/banners/id`;
export const UPDATE_BANNER_URL = `${SECURED_URL}/${TOKENIZED}/banners/update`;

// public banner
export const GET_ALL_PUBLIC_BANNER_URL = `${PUBLIC_URL}/${TOKENIZED}/banners`;
export const GET_PUBLIC_BANNER_BY_ID_URL = `${PUBLIC_URL}/${TOKENIZED}/banners/id`;

// image upload
export const UPLOAD_IMAGE = `${SECURED_URL}/aws/upload/any-file`;
export const UPLOAD_PROFILE_IMAGE = `${SECURED_URL}/users/s3/profile-pic/update`;
export const RETRIEVE_IMAGE = `https://d2h2d3rrei12ng.cloudfront.net`;

// global config
export const CREATE_GLOBAL_CONFIG_URL = `${SECURED_URL}/${TOKENIZED}/global-configs/create`;
export const GET_ALL_GLOBAL_CONFIG_URL = `${SECURED_URL}/${TOKENIZED}/global-configs`;
export const GET_GLOBAL_CONFIG_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/global-configs/id`;
export const GET_ACTIVE_GLOBAL_CONFIG_URL = `${SECURED_URL}/${TOKENIZED}/global-configs/active`;
export const GET_PUBLIC_ACTIVE_GLOBAL_CONFIG_URL = `${PUBLIC_URL}/${TOKENIZED}/global-configs/active`;
export const UPDATE_GLOBAL_CONFIG_URL = `${SECURED_URL}/${TOKENIZED}/global-configs/update`;

// restaurant token
export const GET_RESTAURANT_TOKEN_BY_DOMAIN = `${PUBLIC_URL}/get-static-data`;

// order
export const GET_ALL_ORDER_URL = `${SECURED_URL}/${TOKENIZED}/orders/for-restaurant`;
export const GET_ORDER_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/orders/for-restaurant/by-id`;
export const GET_ORDER_STATE_URL = `${SECURED_URL}/${TOKENIZED}/orders/state`;
export const UPDATE_ORDER_STATE_URL = `${SECURED_URL}/${TOKENIZED}/orders/state/update`;
export const UPDATE_ORDER_PAID_URL = `${SECURED_URL}/${TOKENIZED}/orders/paid`;
export const GET_ORDER_HISTORY_URL = `${SECURED_URL}/${TOKENIZED}/orders/history/for-restaurant`;

// branch order
export const GET_ALL_BRANCH_ORDER_URL = `${SECURED_URL}/${TOKENIZED}/orders/for-branch`;
export const GET_ALL_ASK_HOST_LIST_URL = `${SECURED_URL}/${TOKENIZED}/host-calls`;
export const GET_BRANCH_ORDER_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/orders/for-branch/by-id`;
export const UPDATE_BRANCH_ORDER_URL = `${SECURED_URL}/${TOKENIZED}/orders/update`;
export const URGENT_BRANCH_ORDER_URL = `${SECURED_URL}/${TOKENIZED}/orders/urgent/update`;
export const CREATE_BRANCH_ORDER_URL = `${SECURED_URL}/${TOKENIZED}/orders/for-branch/create/order`;
export const CREATE_BRANCH_QR_CODE_ORDER_URL = `${PUBLIC_URL}/${TOKENIZED}/orders/create/order`;
export const GET_BRANCH_ORDER_HISTORY_URL = `${SECURED_URL}/${TOKENIZED}/orders/history/for-branch`;
export const GET_BRANCH_ORDER_COUNT_BY_STATUS = `${SECURED_URL}/${TOKENIZED}/orders/counts/for-branch`;
export const CANCEL_BRANCH_ORDER = `${SECURED_URL}/${TOKENIZED}/orders/cancel-order`;
export const ASSIGN_DOORDASH_DELIVERY_PARTNER = `${SECURED_URL}/${TOKENIZED}/orders/create/doordash-delivery`;
export const GET_ALL_DOORDASH_ORDER_STATE = `${SECURED_URL}/${TOKENIZED}/orders/doordash-delivery`;

// customer
export const GET_ALL_CUSTOMER_URL = `${SECURED_URL}/${TOKENIZED}/customers`;
export const CREATE_CUSTOMER_URL = `${SECURED_URL}/${TOKENIZED}/customers/create`;

// report
export const GET_ALL_OWNER_REPORT_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/total-count`;
export const GET_RESTAURANT_EARNING_GRAPH_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/branch-earnings/graph`;
export const GET_RESTAURANT_EARNING_BARCHART_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/branch-earnings/barchart`;
export const GET_RESTAURANT_ORDER_BARCHART_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/branch-orders/barchart`;
export const GET_RESTAURANT_ORDER_LINE_CHART_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/branch-orders/graph`;
export const GET_ALL_RESTAURANT_TOP_PRODUCT_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/top-products`;
export const GET_ALL_RESTAURANT_TOP_BRANCH_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/top-branches`;
export const GET_ANALYTICS_ORDER_SUMMARY_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/order-count/by-status`;
export const GET_ANALYTICS_ORDER_TYPE_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/order-count/by-type`;
export const GET_MONTHLY_YEARLY_CUSTOMER_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/get-monthly-yearly-customers`;
export const GET_RESTAURANT_PRODUCT_REVIEW_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/latest-product-review`;
export const GET_RESTAURANT_ORDER_REVIEW_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/latest-customer-review`;
export const GET_RESTAURANT_ORDER_SALE_REPORT_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/sales-record/by-date`;
export const GET_RESTAURANT_ORDER_ITEM_SALE_REPORT_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-restaurant/sales-item-record/by-date`;

export const GET_EARNING_BY_PAYMENT_REPORT_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/sales-record/by-payment-type`;

// branch report
export const GET_BRANCH_REPORT_TOTAL_COUNT_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/total-count`;
export const GET_BRANCH_REPORT_TOP_PRODUCT_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/top-products`;
export const GET_BRANCH_REPORT_ORDER_TYPE_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/order-count/by-type`;
export const GET_BRANCH_REPORT_ORDER_SUMMARY_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/order-count/by-status`;
export const GET_BRANCH_REPORT_ORDER_LINE_CHART_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/branch-orders/graph`;
export const GET_BRANCH_REPORT_REVIEW_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/latest-customer-review`;
export const GET_BRANCH_ORDER_REPORT_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/sales-record/by-date`;
export const GET_BRANCH_ORDER_REPORT_BY_TABLE_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/sales-record/by-table`;
export const GET_BRANCH_ORDER_ITEM_SALE_REPORT_URL = `${SECURED_URL}/${TOKENIZED}/reports/for-branch/sales-item-record/by-date`;
export const GET_QR_CODE_LAST_ORDER_URL = `${PUBLIC_URL}/${TOKENIZED}/orders/by-uuid`;
export const GET_QR_CODE_LAST_ORDER_STATE_URL = `${PUBLIC_URL}/${TOKENIZED}/orders/state`;
export const AUTO_COMPLETE_BRANCH_ORDER_URL = `${SECURED_URL}/${TOKENIZED}/orders/auto-complete`;
export const DELETE_HOST_URL = `${SECURED_URL}/${TOKENIZED}/host-calls/complete/id`;

// catering
export const CREATE_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/catering-menus/create`;
export const GET_ALL_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/catering-menus`;
export const GET_CATERING_MENU_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/catering-menus/id`;
export const UPDATE_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/catering-menus/update`;

// requested catering
export const GET_ALL_REQUESTED_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/catering-menus/requested-list`;

// branch catering menu -> global
export const GET_ALL_GLOBAL_BRANCH_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/catering-menus/for-branch`;
export const GET_GLOBAL_BRANCH_CATERING_MENU_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/catering-menus/for-branch/id`;
export const REQUEST_BRANCH_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/catering-menus/for-branch/create-request`;
export const UPDATE_REQUESTED_BRANCH_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/catering-menus/for-branch/update-request`;

// branch catering menu
export const ASSIGN_BRANCH_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/branch-catering-menus/assign`;
export const GET_ALL_BRANCH_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/branch-catering-menus`;
export const GET_BRANCH_CATERING_MENU_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/branch-catering-menus/id`;
export const UPDATE_BRANCH_CATERING_MENU_URL = `${SECURED_URL}/${TOKENIZED}/branch-catering-menus/update`;

// branch catering product
export const GET_ALL_KITCHEN_DISPLAY = `${SECURED_URL}/${TOKENIZED}/orders/kitchen-display/data`;

export const GOOGLE_MAP_PLACES = "https://maps.googleapis.com/maps/api/geocode/json";

// global_ingredient management

export const CREATE_INGREDIENT_PRODUCT_CATEGORY = `${SECURED_URL}/${TOKENIZED}/ingredient-categories/create`;
export const GET_ALL_INGREDIENT_PRODUCT_CATEGORY = `${SECURED_URL}/${TOKENIZED}/ingredient-categories`;
export const GET_ALL_INGREDIENT_PRODUCT_CATEGORY_ID = `${SECURED_URL}/${TOKENIZED}/ingredient-categories/id`;
export const UPDATE_INGREDIENT_PRODUCT_CATEGORY = `${SECURED_URL}/${TOKENIZED}/ingredient-categories/update`;
export const CREATE_INGREDIENT = `${SECURED_URL}/${TOKENIZED}/ingredients/create`;
export const GET_ALL_INGREDIENT = `${SECURED_URL}/${TOKENIZED}/ingredients`;
export const GET_ALL_INGREDIENT_ID = `${SECURED_URL}/${TOKENIZED}/ingredients/id`;
export const UPDATE_INGREDIENT = `${SECURED_URL}/${TOKENIZED}/ingredients/update`;
export const GET_ALL_UNIT = `${SECURED_URL}/${TOKENIZED}/units`;
export const GET_BRANCH_INGREDIENT_REPORT = `${SECURED_URL}/${TOKENIZED}/branch-ingredients/for-report`;

export const BRANCH_GET_ALL_INGREDIENT = `${SECURED_URL}/${TOKENIZED}/ingredients/for-branch`;
export const BRANCH_INGREDIENT_ASSIGN = `${SECURED_URL}/${TOKENIZED}/branch-ingredients/assign`;
export const BRANCH_INGREDIENT_CATEGORY = `${SECURED_URL}/${TOKENIZED}/ingredient-categories/for-branch`;
export const BRANCH_GET_ALL_UNITS = `${SECURED_URL}/${TOKENIZED}/units/for-branch`;
export const BRANCH_INGREDIENTS_CREATE_REQUEST = `${SECURED_URL}/${TOKENIZED}/ingredients/for-branch/create-request`;
export const GET_BRANCH_INGREDIENT_ID = `${SECURED_URL}/${TOKENIZED}/ingredients/for-branch/id`;
export const UPDATE_BRANCH_INGREDIENT_ID = `${SECURED_URL}/${TOKENIZED}/ingredients/for-branch/update-request`;
export const GET_REQUEST_INGREDIENT_LIST = `${SECURED_URL}/${TOKENIZED}/ingredients/requested-list`;
export const GET_BRANCH_INGREDIENT_LIST = `${SECURED_URL}/${TOKENIZED}/branch-ingredients`;
export const GET_BRANCH_INGREDIENT_LIST_BY_ID = `${SECURED_URL}/${TOKENIZED}/branch-ingredients/id`;
export const UPDATE_INGREDIENT_BRANCH_LIST_BY_ID = `${SECURED_URL}/${TOKENIZED}/branch-ingredients/update`;

// supplier
export const GET_SUPPLIER_LIST = `${SECURED_URL}/${TOKENIZED}/suppliers`;
export const POST_SUPPLIER = `${SECURED_URL}/${TOKENIZED}/suppliers/create`;
export const UPDATE_SUPPLIER = `${SECURED_URL}/${TOKENIZED}/suppliers/update`;
export const GET_SUPPLIER_BY_ID = `${SECURED_URL}/${TOKENIZED}/suppliers/id`;

// branch supplier
export const ASSIGN_INGREDIENT_TO_SUPPLIER = `${SECURED_URL}/${TOKENIZED}/branch-ingredients/assign/to-supplier`;

// agent
export const GET_AGENT_LIST = `${SECURED_URL}/${TOKENIZED}/agents`;
export const POST_AGENT = `${SECURED_URL}/${TOKENIZED}/agents/create`;
export const UPDATE_AGENT = `${SECURED_URL}/${TOKENIZED}/agents/update`;
export const GET_AGENT_BY_ID = `${SECURED_URL}/${TOKENIZED}/agents/id`
export const GET_SUPPLIER_BY_INGREDIENT_ID = `${SECURED_URL}/${TOKENIZED}/suppliers/by/branch-ingredient-id`;
export const POST_SUPPLIERS_BY_INGREDIENT_ID = `${SECURED_URL}/${TOKENIZED}/branch-ingredients/assign/suppliers`;
export const STOCK_TRANSACTION_REPORT_BY_ID = `${SECURED_URL}/${TOKENIZED}/stock-transactions/report/by-agent`;

// product_stock-transaction
export const CREATE_PRODUCT_STOCK_TRANSACTION_URL = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/in`;
export const CREATE_STOCK_TRANSACTION_URL = `${SECURED_URL}/${TOKENIZED}/stock-transactions/in`;
export const STOCK_TRANSACTION_OUT_URL = `${SECURED_URL}/${TOKENIZED}/stock-transactions/out`;
export const UPDATE_STOCK_TRANSACTION_OUT_URL = `${SECURED_URL}/${TOKENIZED}/stock-transactions/out/update`;
export const GET_ALL_STOCK_TRANSACTION_URL = `${SECURED_URL}/${TOKENIZED}/stock-transactions`;
export const GET_STOCK_TRANSACTION_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/stock-transactions/id`;
export const UPDATE_STOCK_TRANSACTION_IN_URL = `${SECURED_URL}/${TOKENIZED}/stock-transactions/in/update`;
export const GET_STOCK_TRANSACTION_BY_INGREDIENT_ID = `${SECURED_URL}/${TOKENIZED}/stock-transactions/stocks`;
export const ADJUST_MULTIPLE_STOCK_TRANSACTION_PAYMENT = `${SECURED_URL}/${TOKENIZED}/stock-transactions/adjust`;

// branch config
export const CREATE_BRANCH_CONFIG_URL = `${SECURED_URL}/${TOKENIZED}/branch-configs/create`;
export const GET_ALL_BRANCH_CONFIG_URL = `${SECURED_URL}/${TOKENIZED}/branch-configs`;
export const GET_BRANCH_CONFIG_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/branch-configs/id`;
export const GET_ACTIVE_BRANCH_CONFIG_URL = `${SECURED_URL}/${TOKENIZED}/branch-configs/active`;
export const GET_PUBLIC_ACTIVE_BRANCH_CONFIG_URL = `${PUBLIC_URL}/${TOKENIZED}/branch-configs/active`;
export const UPDATE_BRANCH_CONFIG_URL = `${SECURED_URL}/${TOKENIZED}/branch-configs/update`;

// agent payment
export const GET_ALL_AGENT_PAYMENT_URL = `${SECURED_URL}/${TOKENIZED}/branch-user-payments`;
export const GET_AGENT_PAYMENT_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/branch-user-payments/by-id`;
export const CREATE_AGENT_PAYMENT_URL = `${SECURED_URL}/${TOKENIZED}/branch-user-payments/create/for-agent`;
export const CREATE_STOCK_PAYMENT_URL = `${SECURED_URL}/${TOKENIZED}/branch-user-payments/create/for-agent/by-stock`;
export const UPDATE_GET_AGENT_PAYMENT_URL = `${SECURED_URL}/${TOKENIZED}/branch-user-payments/update/for-agent`;
export const CANCEL_AGENT_PAYMENT_URL = `${SECURED_URL}/${TOKENIZED}/branch-user-payments/cancel/for-agent`;

// ready_product_stock-transaction
export const READY_PRODUCT_CREATE_PRODUCT_STOCK_TRANSACTION_URL = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/in`;
export const READY_PRODUCT_CREATE_STOCK_TRANSACTION_URL = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/in`;
export const READY_PRODUCT_STOCK_TRANSACTION_OUT_URL = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/out`;
export const READY_PRODUCT_UPDATE_STOCK_TRANSACTION_OUT_URL = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/out/update`;
export const READY_PRODUCT_GET_ALL_STOCK_TRANSACTION_URL = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions`;
export const READY_PRODUCT_GET_STOCK_TRANSACTION_BY_ID_URL = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/id`;
export const READY_PRODUCT_UPDATE_STOCK_TRANSACTION_IN_URL = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/in/update`;
export const READY_PRODUCT_CANCEL_STOCK_TRANSACTION_URL = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/cancel`;
export const READY_PRODUCT_GET_STOCK_TRANSACTION_BY_INGREDIENT_ID = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/stocks`;
export const READY_PRODUCT_ADJUST_MULTIPLE_STOCK_TRANSACTION_PAYMENT = `${SECURED_URL}/${TOKENIZED}/product-stock-transactions/adjust`;

// address
export const GET_ALL_ADDRESS_BY_CUSTOMER = `${SECURED_URL}/addresses/for-branch`;

// payment
export const CREATE_BKASH_PAYMENT_URL = `${PUBLIC_URL}/${TOKENIZED}/bkash/payment`;
export const GET_VALIDATE_COUPON_URL = `${PUBLIC_URL}/${TOKENIZED}/coupons/validate/by-code`;
export const GET_ALL_TRANSACTIONS_URL = `${SECURED_URL}/${TOKENIZED}/bkash/transactions`;


// system config
export const GET_ALL_SYSTEM_CONFIGS = `${PUBLIC_URL}/${TOKENIZED}/system-configs`;

//
export const GET_BRANCH_PRODUCT_BY_QR_CODE_URL = `${PUBLIC_URL}/${TOKENIZED}/branch-products`;
export const GET_RESTAURANT_BY_ID_URL = `${PUBLIC_URL}/${TOKENIZED}/restaurant-info`;

// ask host
export const ASK_HOST_API_URL = `${PUBLIC_URL}/${TOKENIZED}/ask-host`;

// Print
export const PRINT_URL = "http://localhost:3300/print";